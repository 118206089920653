import React, { useContext, useEffect } from 'react';
import { navigate } from 'gatsby';
import AuthContext from '../context/AuthProvider';
import Layout from '../components/organisms/Layout/Layout';
import SEO from '../components/organisms/Seo/Seo';
import ForgotPasswordForm from '../components/organisms/ForgotPasswordForm/ForgotPasswordForm';
import Container from '../components/atoms/Container/Container';
import PageBanner from '../components/atoms/PageBanner/PageBanner';

const ForgotPasswordPage = () => {
  const auth = useContext(AuthContext);
  const isLoggedIn = auth && auth.state.isLoggedIn;
  const userChecked = auth && auth.state.userChecked;

  useEffect(() => {
    if (isLoggedIn) {
      navigate(`/account/`);
    }
  }, [isLoggedIn]);

  if (!isLoggedIn && userChecked) {
    return (
      <Layout>
        <SEO title="Reset Password" />
        <PageBanner
          breadcrumb="Reset Password"
          title="Reset Password"
          color="latte"
          imageSize="medium"
        >
          <p>
            Fill in your email below to request a new password. An email will be
            sent to the address below containing a link to verify your email
            address.
          </p>
        </PageBanner>
        <Container size="large">
          <ForgotPasswordForm />
        </Container>
      </Layout>
    );
  } else return null
};

export default ForgotPasswordPage;
